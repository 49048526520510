import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function useSkillsList() {
  const toast = useToast();
  const refSkillsTable = ref(null);

  const perPageOptions = [10, 25, 50, 100];
  const perPage = ref(10);
  const searchQuery = ref("");
  const totalSkills = ref(0);
  const currentPage = ref(1);
  const isAddNewSidebarVisible = ref(false);
  const isUpdateSidebarActive = ref(false);
  const isLoading = ref(false);

  const skillStatuses = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const fetchSkills = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("q", searchQuery.value);
      formData.append("page", currentPage.value);
      formData.append("perPage", perPage.value);

      const response = await axios.post("allPermissions", formData);
      const { data } = response;
      totalSkills.value = data.count;
      return data.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Error fetching skills list",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const addSkill = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("type", data.type);
      formData.append("desc", data.desc);
      const resp = await axios
        .post("permissions", formData)
        .then((response) => {
          fetchSkills();
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title:
                typeof error.response !== "undefined"
                  ? error.response.data.error.message
                  : error.message || "Unable to save new permission",
              icon: "AlertTrainingIcon",
              variant: "danger",
            },
          });
        });
      return resp;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new permission",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const getSkillData = async (skillID) => {
    try {
      const response = await axios.get(`permissions/${skillID}`);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new skill",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const updateSkill = async (skill) => {
    try {
      const formData = new FormData();
      formData.append("name", skill.name);
      formData.append("type", skill.type);
      formData.append("desc", skill.desc);
      const response = await axios.put(`permissions/${skill._id}`, formData);

      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });

      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to update the permission",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };
  const deletePermission = async (skillID) => {
    try {
      const response = await axios.delete(`permissions/${skillID}`);
      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to delete the permission",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const tableColumns = [
    { key: "name", sortable: true },
    { key: "desc", sortable: false },
    { key: "type", sortable: true },
    { key: "actions" },
  ];

  const resolveSkillStatusVariant = (status) => {
    if (status == 1) return "success";
    return "danger";
  };

  const dataMeta = computed(() => {
    const localItemsCount = refSkillsTable.value ? refSkillsTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSkills.value,
    };
  });

  const refetchData = () => {
    refSkillsTable.value.refresh();
  };

  // let's watch if there is something changed in the skill
  watch([perPage, searchQuery, currentPage], () => {
    refetchData();
  });

  return {
    perPage,
    perPageOptions,
    searchQuery,
    fetchSkills,
    tableColumns,
    deletePermission,
    resolveSkillStatusVariant,
    refSkillsTable,
    totalSkills,
    currentPage,
    dataMeta,
    isAddNewSidebarVisible,
    isUpdateSidebarActive,
    skillStatuses,
    addSkill,
    refetchData,
    getSkillData,
    updateSkill,
    isLoading,
  };
}
